footer {
    background: var(--blue-color);
    padding: 5rem 0;
}

.footer--footer-column {
    flex: 1;
    padding: 0 2rem;
    flex-direction: column;
    display: flex;
}

@media all and (max-width: 704px) {
    .footer--footer-column {
        flex: 0 0 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;
    }

    footer .logo img {
        object-position: center;
    }
}

.footer--footer-column a {
    text-decoration: none;
    font-weight: 600;
    margin: 1rem 0 .5rem 0;
    transition: .345s ease color;
    color: #FFF;
}

.footer--footer-column a:hover {
    color: var(--yellow-color);
}

.footer--footer-column a:first-of-type,
.footer--footer-column span:first-of-type {
    margin-top: .35rem;
}

.footer--footer-column span {
    display: block;
    margin: 1rem 0 .5rem;
    font-weight: 600;
    color: #FFF;
}