html,body {
  font-size: 16px;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'HK Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: auto;
  min-height: 100%;
}

* > * {
  box-sizing: border-box;
  font-family: 'HK Grotesk', sans-serif;
}