.contact-form {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(calc(100% - 85px));
    width: 300px;
    z-index: 999;
}

.contact-form--header {
    height: 85px;
    width: 100%;
    padding: 1rem;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: var(--yellow-color);
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

@media all and (max-width: 974px) {
    .contact-form {
        display: none;
    }
}

.contact-form--header .right {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.3;
}

.contact-form--body {
    background: #FFF;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
    padding: 1rem;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.contact-form input {
    font-size: 1rem;
    width: 100%;
    border: 2px solid #EDEDED;
    border-radius: 4px;
    padding: .75rem;
    outline: none;
    transition: .345s ease border-color;
    font-weight: bold;
    margin-bottom: 2rem;
}

.contact-form input:last-of-type {
    margin-bottom: 1rem;
}

.contact-form input:focus {
    border-color: var(--blue-color);
}