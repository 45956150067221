.lightning-bolt {
    position: relative;
}

.bolt {
    height: 10px;
    position: absolute;
    width: 2px;
    background: var(--yellow-color);
    transition: .345s ease all;
}

.bolt.top {
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 3px)) scaleY(0);
    transform-origin: 100% 100%;
}

.bolt.bottom {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% + 3px));
}

.bolt.left {
    top: 25%;
    left: 0%;
    transform: translate(calc(-100% - 8px), 0%) rotate(90deg);
}

.bolt.right {
    top: 25%;
    right: 0%;
    transform: translate(calc(100% + 8px), 0%) rotate(90deg);
}

.lightning-bolt:hover .bolt.top {
    transform: translate(-50%, calc(-100% - 3px)) scaleY(1);
    transform-origin: 0% 100%;
}