:root {
  --yellow-color: #FFBE16;
  --blue-color: #003482;
  --grey-color: #F9F9F9;
  --grey-text: #95989c;
}

#vue-electric {
  font-size: 16px;
}

img.img-block {
  width: 100%;
  display: block;
}

.logo-color {
  color: var(--yellow-color); 
  font-weight: bold; 
  margin-left: 1rem;
}

.hero {
  height: calc(70vh + 60px);
  padding-top: 60px;
  background-image: url('../public/assets/outdoor-light-home-cover.jpg');
  background-size: cover;
  background-position: center center;
  position: relative;
}

.hero::before {
  content: '';
  background: var(--blue-color);
  opacity: .3;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero h1 {
  padding-top: 0rem;
  font-size: 3rem;
}

.bg-yellow {
  background: var(--yellow-color);
}


.bg-grey {
  background: var(--grey-color);
}

header {
  padding: 8.5rem 0;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.header--text {
  width: 75%;
}

.header--text-container {

}

@media screen and (max-width: 768px) {
  .header--text {
    width: 100%;
  }
  
  .header--text-container {
    flex: 0 0 100% !important;
  }
}

.bg-blue {
  background: var(--blue-color);
}


.bg-yellow .header--text,
.bg-blue .header--text {
  color: #FFF;
}

.bg-grey .header--text {
  color: var(--yellow-color);
}

.header--text h1 {
  font-size: 3rem;
}

a, 
a:visited, 
a:active {
  color: inherit;
}

.header--text h2,
.testimonial-slider h2 {
  font-weight: 600;
}

p {
  line-height: 1.6;
  font-size: 1.15rem;
}

a {
  text-decoration: none;
}

.grey {
  color: var(--grey-text);
}

.yellow {
  color: var(--yellow-color);
}

@media screen and (max-width: 1200px) {
  .hero h1 {
    font-size: 2.5rem;
  }
}