.page-contact {
    padding-bottom: 10rem;
}

::placeholder,
::-ms-input-placeholder,
:-ms-input-placeholder  { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: red;
    opacity: 1; /* Firefox */
}
  

.page-contact input[type="text"] {
    border-style: none;
    border-bottom: 1px solid var(--grey-text);
    margin-bottom: 4rem;
    font-size: 1.5rem;
    font-weight: 500;
    outline: none;
    display: inline-flex;
    align-self: flex-start;
    flex:0 0 calc(50% - 1rem);
    border-radius: 0;
    width: 100%;
}

.page-contact input[type="text"].error {
    border-bottom: 1px solid red;
}

.page-contact select {
    border-style: none;
    border-bottom: 1px solid var(--grey-text);
    margin-bottom: 4rem;
    font-size: 1.5rem;
    font-weight: 500;
    outline: none;
    width: 100%;
    display: inline-flex;
    align-self: flex-start;
    flex:0 0 calc(50% - 1rem);
    border-radius: 0;
    background: #FFF;
    border-radius: 0;
    -webkit-appearance: none;
    margin-top: 2px;
}

.page-contact textarea {
    position: relative;
    width: 100%;
    min-height: 2.5rem;
    border: none;
    border-bottom: 1px solid var(--grey-text);
    display: block;
    outline: none;
    font-size: 1.5rem;
    resize: none;
    font-weight: 500;
    margin-top: 2rem;
    border-radius: 0;
}

.button-send-form {
    background: var(--yellow-color);
    padding: .75rem 1rem;
    color: #FFF;
    margin-left: auto;
    margin-top: 4rem;
    display: flex;
    align-items: center;
    transition: all ease .345s;
}

.button-send-form:hover {
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
}

.button-send-form[disabled],
.button-send-form[disabled]:hover {
    background: #F9F9f9 !important;
    color: #999 !important;
    box-shadow: 0 0 0 0 !important;
}

.button-send-form svg {
    margin-left: 1rem
}

.errors-container {
    width: 50%;
    background: #fd8d8b;
    color: #FFF;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 3rem;
    display: none;
}

@media all and (max-width: 600px) {
    .errors-container {
        width: 100%;
    }
}

.errors-container.visible {
    display: block;
}

.errors-container h4 {
    margin-top: 0;
}

.errors-container ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.errors-container ul li {
    font-weight: bold;
    text-transform: capitalize;
}

@media all and (max-width: 600px) {
    .button-send-form {
        margin-right: auto;
    }

    .ctact {
        flex: 0 0 100%;
    }

    .page-contact input[type="text"],
    .page-contact select {
        width: 100%;
        flex: 0 0 100%;
    }
}
