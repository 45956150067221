.pop-up-slide-show {
    position: fixed;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: .345s ease opacity, 0s linear visibility .345s;
}

.pop-up-slide-show[open] {
    visibility: visible;
    opacity: 1;
    transition: .345s ease opacity, 0s linear visibility;
}

.background-trigger {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.service-slider {
    width: 100%;
    height: 45vw;
    opacity: 0;
    transition: .345s ease all .345s;
}

@media all and (max-width: 600px) {
    .service-slider {
        height: 59vw;
    }
}

.pop-up-slide-show[open] .service-slider {
    opacity: 1;
}

.service-slide {
    width: 100%;
    height: 100%;
    z-index: -1;
}

.service-slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.close-pop-up {
    position: absolute;
    height: 30px;
    width: 30px;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999999999;
    cursor: pointer;
}

.close-pop-up::before {
    content: '';
    width: 30px;
    height: 2px;
    background: #FFF;
    display: block;
    transform: rotate(45deg);
    position: absolute;
    cursor: pointer;
}

.close-pop-up::after {
    content: '';
    width: 30px;
    height: 2px;
    background: #FFF;
    display: block;
    transform: rotate(-45deg);
    position: absolute;
}


