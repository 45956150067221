.page-services {
    min-height: 100%;
    width: 100%;
}

.services-content {
    padding: 10rem 0;
}

.service-row {
    padding-top: 10rem;
}

.service-row:first-of-type {
    padding-top: 0;
}

.service-countdown {
    color: #95989c;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.short-line {
    background: #95989c;
    height: 2px;
    width: 20px;
    display: inline-block;
    margin-right: 1rem;
    margin-top: 4px;
}

.services-content--container {
    padding-left: 1rem;
}

.services-content--image-container {

}

@media all and (max-width: 768px) {
    .services-content--container {
        order: 1;
        flex: 0 0 100%;
        padding: 0;
    }

    .services-content--image-container {
        order: 2;
        justify-content: center !important;
        flex: 0 0 100%;
        padding-top: 6rem;
    }
}

.services-content--image {
    max-width: 450px;
    background: var(--grey-color);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    transition: .5s ease all;
}

.service-row:hover .services-content--image {
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.2);
    transform: scale(1.02);
}

.services-content--image img {
    display: block;
    width: 100%;
}

.services-content--text {
    font-size: 1.15rem;
    font-weight: 500;
}

.services-content h2.service-title {
    font-size: 3rem;
}

.services-content--see-more {
    display: block;
    margin-top: 6rem;
    display: flex;
    align-items: center;
    color: var(--yellow-color);
    cursor: pointer;
    transition: .345s ease all;
    text-shadow: 0 0 0px rgba(0,0,0,0.1);
}

.services-content--see-more:hover {
    transform: translateX(5px);
}