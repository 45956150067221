.home {
    position: relative;
}

.intro-quote {
    max-width: 50%;
    width: calc(100% - 300px);
    padding: 0 0 calc(100px);
    color: var(--yellow-color);
}

@media all and (max-width: 768px) {
    .intro-quote {
        text-align: center;
        width: 100%;
        max-width: unset;
    }

    .intro-quote button {
        margin: auto;
    }
}


.intro-quote.right {
    padding: 3rem 0;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #000;
    /* min-height: 450px; */
    /* height: 100%; */
    position: relative;
    max-width: 66.6666%;
}

@media all and (max-width: 974px) {
    .intro-quote.right {
        width: 100%;
        max-width: unset;
    }
}

.intro-link {
    color: var(--yellow-color);
    font-weight: bold;
    font-size: 1.25rem;
    border-bottom: var(--blue-color);
    border-bottom: 2px solid var(--blue-color);
    padding-bottom: 3px;
}

.grey-section {
    background: var(--grey-color);
    padding: 6rem 0;
}

.icon-slider {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;
}

.icon-slider--container {
    flex: 0 0 calc(33.33333% - 2rem);
    display: flex;
    margin-bottom: 3rem;
}


@media screen and (max-width: 1014px) {
    .icon-slider--container { 
        flex: 0 0 calc(50% - 1.5rem);
    }
}

@media screen and (max-width: 600px) {
    .icon-slider--container { 
        flex: 0 0 calc(100%);
    }
}

.icon-slider--container .icon-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 2rem;
    border-radius: 4px;
    transition: .345s ease all;
    cursor: pointer;
    background: #FFF;
}

.icon-slider--container .icon-card:hover {
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.1);
    background: #ffeaa7;
}

.icon-description { 
    color: #767676;
}

.testimonial-slider {
    width: 100%;
    /* height: 100%; */
    /* position: absolute; */
    color: var(--grey-text);
}

.testimonial-slider .flickity-button,
.testimonial-slider .flickity-page-dots {
    display: none;
}

.testimonial-slide {
    width: 100%;
    height: auto;
    display: block;
    display: flex;
    align-items: center;
    padding: 0 4rem;
    text-align: left;
}

.flickity-page-dots {
    /* bottom: 75px; */
    padding-left: 4rem;
    text-align: left;
}

.flickity-page-dots .dot {
    width: 5px;
    height: 5px;
    background: var(--yellow-color);
}

@media screen and (max-width: 660px) {
    .testimonial-slide {
        padding: 0 0;
        align-items: flex-start;
    }

    .intro-quote.right {
        /* height: 600px; */
    }

    .intro-quote.right h1 {
        padding-left: 0 !important;
        text-align: left;
    }

    .flickity-page-dots {
        text-align: center;
        padding-left: 0;
    }
}