nav {
    background: transparent;
    width: 100%;
    display: flex;
    height: 61px;
    justify-content: flex-start;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    color: var(--yellow-color);
    z-index: 99999;
    transition: .345s ease all;
}

nav a:not(.logo) {
    font-weight: bold;
    margin-left: 2rem;
    font-size: 1.125rem;
    text-decoration: none;
}

nav a:not(.logo) {
    border-bottom: 2px solid transparent;
}

nav a:not(.logo):hover,
nav a:not(.logo).selected {
    border-bottom: 2px solid var(--yellow-color);
}

.services-page nav a:not(.logo):hover,
.services-page nav a:not(.logo).selected {
    border-bottom: 2px solid #FFF;
}

.services-page nav.bg-white a:not(.logo):hover,
.services-page nav.bg-white a:not(.logo).selected {
    border-bottom: 2px solid var(--yellow-color);
}

nav.visible .hamburger.white::before,
nav.visible .hamburger.white::after {
    background: var(--yellow-color) !important;
}

nav.visible .logo .logo-color {
    color: var(--yellow-color) !important;
}

nav .hamburger {
    height: 15px;
    width: 30px;
    position: relative;
    display: none;
    z-index: 999;
    cursor: pointer;
}

nav .hamburger::before,
nav .hamburger::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    background: var(--yellow-color);
}

nav .hamburger::before {
    top: 0;
}

nav .hamburger::after {
    bottom: 0;
}

@media screen and (max-width: 837px) {
    nav a:not(.logo) {
        display: none;
    }

    nav .hamburger {
        display: block;
    }
}

.popup {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    background: #FFF;
    opacity: 0;
    visibility: hidden;
    transition: .345s ease opacity, .345s ease transform, 0s linear visibility .345s;
    transform: scale(.95);
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

nav .popup.visible a {
    display: block;
    color: var(--yellow-color);
    font-size: 3rem;
    margin-left: 0;
}

.popup.visible {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    transition: .345s ease opacity, .345s ease transform, 0s linear visiblity;
}

nav a.logo {
    margin-left: 0;
    z-index: 999;
}

.logo-svg {
    max-height: 31px;
    max-width: 100%;
    width: 100%;
    object-fit: contain;
    object-position: left;
}

nav.bg-white {
    background: #FFF;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    color: var(--yellow-color) !important;
}

.hamburger.white::after,
.hamburger.white::before {
    background: #FFF;
}
