.about-content {
    padding: 8.5rem 0;
}

.about-content--header-image {
    max-width: 360px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.35);
}

.about-content--header-image img {
    display: block;
    width: 100%;
}

.about-content--about-text {
    font-size: 1.15rem;
    font-weight: 500;
}

.about-slider {
    background: var(--grey-color);
    padding: 5rem 0;
}

.about-slider .slide {
    width: 45vw;
    height: 45vw;
    padding: 3rem 3rem;
    display: flex;
    align-items: center;
}

@media all and (max-width: 768px) {
    .about-slider .slide {
        height: 75vw;
        width: 75vw;
        padding: 1rem;
    }

    .about--section-title {
        flex: 0 0 100% !important;
    }

    .flickity-button {
        display: none;
    }
}

.about-slider .slide img {
    display: block;
    width: 100%;
    /* height: 100%; */
    /* object-fit: contain; */
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.15);
}

.flickity-button,
.flickity-button:hover {
    background: var(--yellow-color);
    border-radius: 50%;

    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.15);
}

.flickity-button .flickity-button-icon {
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    transform: translate(-50%,-50%);
}
