button {
    font-size: inherit;
    padding: .75rem 1rem;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    transition: .345s ease all;
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.15);
}

button:hover {
    background: var(--yellow-color) !important;
    color: #FFF !important;
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.15);
}