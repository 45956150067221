.layout--container {
    width: 85%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    margin: 0 auto;
    position: relative;
}

@media all and (max-width: 1200px) {
    .layout--container {
        width: calc(100% - 2rem);
    }
}

.layout--container .justify-start {
    justify-content: flex-start;
}

.layout--container .justify-end {
    justify-content: flex-end;
}

.layout--container .justify-center {
    justify-content: center;
}

.layout--container .space-around {
    justify-content: space-around;
}

.layout--container .justify-between {
    justify-content: space-between
}

.layout--container .justify-evenly {
    justify-content: space-evenly;
}

.layout--container .align-start {
    align-items: flex-start;
}

.layout--container .align-end {
    align-items: flex-end;
}

.layout--container .align-center {
    align-items: center;
}

.layout--container .align-baseline {
    align-items: baseline
}

.layout--container .align-stretch {
    justify-content: stretch;
}

.layout--container .align-unset {
    justify-content: unset;
}

.layout--container__row {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
}

.layout--container__column {
    display: block;
    flex: 0 0 50%;
    flex-grow: 0;
}

.layout--container__column.flex {
    display: flex;
    height: 100%;
}

.layout--container__column.wrap,
.layout--container__row.wrap {
    flex-wrap: wrap;
}
/* 
@media all and (max-width: 414px) {
    .col-xs-1 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-xs-2 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-xs-3 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-xs-4 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-xs-5 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-xs-6 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-xs-7 {
        flex: 0 0 calc(1 / 12 * 100%);
    }
    
    .col-xs-8 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-xs-9 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-xs-10 {
        flex: 0 0 calc(100% * (10 / 12));
    }

    .col-xs-11 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-xs-12 {
        flex: 0 0 calc(1 / 12 * 100%);
    }
}

@media (max-width: 600px) and (min-width: 415px) {
    .col-sm-1 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-sm-2 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-sm-3 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-sm-4 {
        flex: 0 0 calc(1 / 12 * 100%);
    }
    
    .col-sm-5 {
        flex: 0 0 calc(1 / 12 * 100%);
    }
    
    .col-sm-6 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-sm-7 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-sm-8 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-sm-9 {
        flex: 0 0 calc(1 / 12 * 100%);
    }

    .col-sm-10 {
        flex: 0 0 calc(10 / 12 * 100%);
    }
} */